<template>
    <div class="news-center-details">
        <div class="title" @click="nav">
            您的位置：资讯详情
        </div>
        <div class="contantBox">
            <div class="contant">
                <div class="title-box">
                    {{ form.title }}
                </div>
                <div class="jianjie-box">
                    <div>来源：{{ form.author }}</div>
                    <div class="line"></div>
                    <div>时间：{{ form.update_time }}</div>
                </div>
                <div class="value">
                    <div v-html="form.content"></div>
                </div>
                <div class="button-box">
                    <div class="button1 button" @click="handlePrevious">上一篇：{{ index == 0 ? '当前为第一篇' : tableData[index - 1].title }}</div>
                    <div class="button2 button" @click="handleNext">下一篇：{{ index >= tableData.length - 1 ? '当前为最后一篇' : tableData[index + 1].title }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '../assets/css/common.css'
import '@/views/scss/news-center-details.scss'

export default {
    name: "NewsCenterDetails",
    data() {
        return {
            tableData: [],
            id: '', // 当前页面id
            form: {},
            index: 0, // 当前页面下标

        }
    },
    created() {

    },
    mounted() {
        this.id = this.$route.query.id
        this.InterfaceList()
        this.getList()
    },
    methods: {
        /**当前位置返回*/
        nav() {
            this.$router.go(-1);
        },
        async InterfaceList() {
            //新闻列表
            await this.$axios.post('pcapi/index/article', {}).then(res => {
                if (res.data.code == 1) {
                    this.tableData = res.data.data.list
                    this.tableData.map((item, index) => {
                        if (this.id == item.id) {
                            this.index = index
                            console.log('this.index', this.index)
                        }
                    })
                }
            })
        },
        // 上一篇
        handlePrevious() {
            if (this.index === 0) {
                // 提示已经是第一篇或者禁用上一篇按钮 自由发挥咯
            } else {
                this.index--
                this.id = this.tableData[this.index].id // 获取上一篇id 在getList()里需要
                this.getList() // 获取详情数据的接口
            }
        },
        // 下一篇
        handleNext() {
            if (this.index >= this.tableData.length - 1) {
                // 提示已经是最后一篇或者禁用下一篇按钮 自由发挥咯
            } else {
                this.index++
                this.id = this.tableData[this.index].id // 获取下一篇id 在getList()里需要
                this.getList() // 获取详情数据的接口
            }
        },
        async getList() {
            //新闻列表
            await this.$axios.post('pcapi/index/article_detail', {
                id: this.id
            }).then(res => {
                if (res.data.code == 1) {
                    this.form = res.data.data
                }
            })
        },
    }

}
</script>
<style>

</style>
